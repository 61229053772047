<template>
  <div class="page">
    <el-form :model="queryParams" :inline="true" ref="ruleForm" label-width="90px" class="demo-ruleForm">
      <el-form-item label="起止月份：" prop="resource">
        <el-date-picker
          v-model="queryParams.value1"
          type="monthrange"
          unlink-panels
          :picker-options="monthpickoption"
          range-separator="至"
          format="yyyy-MM"
          value-format="yyyy-MM"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="机构">
        <el-select v-model="queryParams.organizationName" placeholder="请选择机构">
          <el-option v-for="(item, index) in organizationNameList" :key="index" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="search" @click="getList">搜索</el-button>
        <el-button type="primary" class="search" plain @click="resetHandler">重置</el-button>
      </el-form-item>
      <el-button type="primary" class="search" style="float: right" @click="exportExl">导出</el-button>
    </el-form>

    <el-table
      :data="tableData"
      border
      v-loading="loading"
      :header-cell-style="headerCellStyle"
      style="width: 100%">
      <template slot="empty">
        <IsEmpty />
      </template>
      <el-table-column
        type="index"
        align="center"
        width="60"
        :index="indexAdd"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="organizationName"
        align="center"
        width="180"
        label="机构名称">
      </el-table-column>
      <el-table-column
        prop="totalNumberBeds"
        align="center"
        width="150"
        label="总床位数(张)">
      </el-table-column>
      <el-table-column
        prop="address"
        align="center"
        label="享受补贴人数（人）">
        <el-table-column
          prop="january"
          width="80"
          v-if="isShowMonth.january"
          align="center"
          label="1月">
        </el-table-column>
        <el-table-column
          prop="february"
          width="80"
          v-if="isShowMonth.february"
          align="center"
          label="2月">
        </el-table-column>
        <el-table-column
          prop="march"
          width="80"
          v-if="isShowMonth.march"
          align="center"
          label="3月">
        </el-table-column>
        <el-table-column
        prop="april"
        width="80"
        v-if="isShowMonth.april"
        align="center"
        label="4月">
      </el-table-column>
        <el-table-column
          prop="may"
          width="80"
          v-if="isShowMonth.may"
          align="center"
          label="5月">
        </el-table-column>
        <el-table-column
          prop="june"
          width="80"
          v-if="isShowMonth.june"
          align="center"
          label="6月">
        </el-table-column>
        <el-table-column
          prop="july"
          width="80"
          v-if="isShowMonth.july"
          align="center"
          label="7月">
        </el-table-column>
        <el-table-column
          prop="august"
          width="80"
          v-if="isShowMonth.august"
          align="center"
          label="8月">
        </el-table-column>
        <el-table-column
          prop="september"
          width="80"
          v-if="isShowMonth.september"
          align="center"
          label="9月">
        </el-table-column>
        <el-table-column
          prop="october"
          width="80"
          v-if="isShowMonth.october"
          align="center"
          label="10月">
        </el-table-column>
        <el-table-column
          prop="november"
          width="80"
          v-if="isShowMonth.november"
          align="center"
          label="11月">
        </el-table-column>
        <el-table-column
          prop="december"
          width="80"
          v-if="isShowMonth.december"
          align="center"
          label="12月">
        </el-table-column>
        <el-table-column
          prop="totalSubsidies"
          align="center"
          label="合计">
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="address"
        align="center"
        label="享受补贴金额合计（元）">
        <el-table-column
          prop="institutionalSubsidy"
          align="center"
          label="机构补贴部分">
        </el-table-column>
        <el-table-column
          prop="allowanceElderly"
          align="center"
          label="老人补贴部分">
        </el-table-column>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin:20px 0"
      background
      v-if="total > 0"
      layout="total, sizes, prev, pager, next"
      :total="total"
      :page-size="queryParams.pageSize"
      :current-page="queryParams.pageNum"
      :page-sizes="[10, 20, 30, 40]"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"/>
  </div>
</template>

<script>
  import {parseTime} from "@/utils"
  import { getServiceSubsidyReport, exportServiceSubsidyStatic, getOrganizationListWithSubsidy } from "@/api/institutionalSubsidies"
  import { downloadStream } from '@/utils/index'
  export default {
    name: "serviceStatisticsReport",
    data() {
      return {
        loading: false,
        isShowMonth: {
          january: false,
          february: false,
          march: false,
          april: false,
          may: false,
          june: false,
          july: false,
          august: false,
          september: false,
          october: false,
          november: false,
          december: false
        },
        queryParams: {
          pageSize: 10,
          pageNum: 1,
          startDate: '',
          endDate: '',
          organizationName: '',
          value1: []
        },
        total: 0,
        tableData: [],
        organizationNameList: [],
        minDate: null,
        maxDate: null,
        monthpickoption: {
          disabledDate: (time) => {
            let firstDay = new Date()
            firstDay.setDate(1)
            firstDay.setMonth(0)
            if (this.minDate) {
              return time.getTime() - this.minDate.getTime() >= 31536000000
            }else {
              return time.getTime() - firstDay.getTime() >= 31536000000
            }
          },
          onPick: ({minDate, maxDate}) => {
            this.minDate = minDate
            this.maxDate = maxDate;
          }
        }

      }

    },
    mounted() {
      this.getTime()
      this.getOrganizationListData()
      this.getList()
    },
    methods: {
      /*序号*/
      indexAdd(index) {
        return index + 1 + (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      },
      /*导出*/
      exportExl() {
        this.exportStatisticsData()
      },
      async exportStatisticsData() {
        let params = JSON.parse(JSON.stringify(this.queryParams))
        params.startDate = params.value1[0]
        params.endDate = params.value1[1]
        Reflect.deleteProperty(params,'value1')
        const res = await exportServiceSubsidyStatic(params)
        downloadStream(res, `服务补贴报表.xlsx`)
      },
      resetHandler() {
        this.queryParams = {
          pageSize: 10,
            pageNum: 1,
            startDate: '',
            endDate: '',
            organizationName: '',
            value1: []
        }
        this.getTime()
        this.getList()
      },
      async getList() {
        this.loading = true
        let params = JSON.parse(JSON.stringify(this.queryParams))
        params.startDate = params.value1[0]
        params.endDate = params.value1[1]
        Reflect.deleteProperty(params,'value1')

        try {
          const res = await getServiceSubsidyReport(params)
          this.tableData = res.data.rows
          this.tableData.forEach(item => {
            let arr = []
            for (let key in item) {
              arr.push(key)
              this.isShowMonth.january  = arr.includes('january')
              this.isShowMonth.february  = arr.includes('february')
              this.isShowMonth.march  = arr.includes('march')
              this.isShowMonth.april  = arr.includes('april')
              this.isShowMonth.may  = arr.includes('may')
              this.isShowMonth.june  = arr.includes('june')
              this.isShowMonth.july  = arr.includes('july')
              this.isShowMonth.august  = arr.includes('august')
              this.isShowMonth.september  = arr.includes('september')
              this.isShowMonth.october  = arr.includes('october')
              this.isShowMonth.november  = arr.includes('november')
              this.isShowMonth.december  = arr.includes('december')
            }
          })
          this.total = res.data.total
          this.loading = false
        }catch (e) {
          this.loading = false
        }

      },
      async getOrganizationListData() {
        const res = await getOrganizationListWithSubsidy()
        this.organizationNameList = res.data
      },
      getTime() {
        let firstDay = new Date()
        firstDay.setDate(1)
        firstDay.setMonth(0)

        let minDate = new Date()
        minDate.setDate(1)
        minDate.setMonth(-1)

        this.minDate = minDate
        let arr = [parseTime(firstDay, '{y}-{m}-{d}'), parseTime(new Date(),'{y}-{m}-{d}')]
        this.queryParams.value1 = arr
      },
      // 翻页
      handleCurrentChange(val) {
        this.loading = true
        this.queryParams.pageNum = val
        this.getList()
      },
      handleSizeChange(val) {
        this.loading = true
        this.queryParams.pageSize = val
        this.getList()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    background-color: #ffffff;
    height: calc(100vh - 145px);
    padding: 30px;
    box-sizing: border-box;
  }
</style>
